<template lang="html">
  <div class="sidebar" >
    <!-- <div class="navbar" id="reponsiveNavBar">
            <div class="container nav-container">
                <input class="checkbox" type="checkbox" name="" id="sidebartoggle" @click='expand = !expand'  :checked="expand"/>
                <label for="sidebartoggle" class="hamburger-lines">
                  <span class="line line1"></span>
                  <span class="line line2"></span>
                  <span class="line line3"></span>
                </label>
              <div class="logo">
              </div>
              <div class="menu-items">
                <img :src="require('@/assets/logo_innopearl.svg')"  class="sidebar_logo" alt="">
                <div class="divider_blue_line"/>
                <MenuSidebar />
              </div>
            </div>
          </div> -->

          <div id="innopearl_sidebar">
             <div class="menu-items" style="text-align:center;">
               <router-link to="/selectYourChallenge" v-if="role == 'Responsable'"> <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>

               <router-link to="/selectYourChallenge" v-if="role == 'Customer'"> <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>
               <router-link to="/expert/my_projects" v-if="role == 'Expert'">  <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>

               <router-link to="/admin/my_projects" v-if="role == 'Admin'"> <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>


              <!-- <router-link to="/" > <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link> -->
            </div>
            <div class="innopearl_sidebar_content">
<!--
              <router-link to="/selectYourChallenge" v-if="role == 'Customer'">  <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>
              <router-link to="/expert/my_projects" v-if="role == 'Expert'">  <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link>

              <router-link to="/admin/my_projects" v-if="role == 'Admin'">  <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo" alt=""></router-link> -->

              <div class="divider_blue_line"/>

              <MenuSidebar />
            </div>

          </div>
  </div>


</template>


<style lang="css" scoped>

  #innopearl_sidebar{
    /* width: 15%; */
     height: 100%;
  /* top: 0;
   left: 0;
   position: fixed; */
   padding-top: 5px;
    /* background: #CCC; */
    overflow: scroll;
    padding-bottom: 50px;
    z-index:40;
  }
  .innopearl_sidebar_content{
    width:80%;
    margin:auto;
  }
  .sidebar_logo{
    margin-left: 20px;
    max-width: 120px;
  }
  .divider_blue_line{
    width: 100%;
    height:2px;
    background-color: #3783C5;
    margin-top:20px;
    margin-bottom: 20px;
  }
.router-link-active,
.router-link-active *{
  color:blue !important;
}

/*



  .navbar {
    width: 100%;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }

  .navbar .menu-items {
    display: flex;
    position: fixed;
background: #FFF;
top: 0;
border: 0;
bottom: 0;
left: 0;
right: 0;

  }

  .navbar .nav-container li {
    list-style: none;
  }

  .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  }


  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 1px;
    width: 1px;
    top: 1px;
    left: 1px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav-container .hamburger-lines {
    display: block;
    height: 20px;
    width: 30px;
    position: fixed;
    z-index: 500;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 30px;
    border-radius: 10px;
    background: #0e2431;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items {
    padding: 50px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }


  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg) translate(-5px, 2px);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
transform: rotate(-45deg) translate(-5px, -3px);
  } */



  /* @media screen and (max-width:1199px){
    #innopearl_sidebar{
      display:none;
    }
    .sidebar_logo{
      max-width: 100%;
      width: 200px;
      margin-left:auto;
      margin-right: auto;
    }

  }
  @media screen and (min-width:1200px){
    #reponsiveNavBar{
        display:none;
    }
  } */




  </style>
<script>
import MenuSidebar from '@/components/sidebar/MenuSidebar.vue';
import { mapState } from 'vuex';
  export default {
      name: 'CustomSidebar',
      components: {
        MenuSidebar
      },
      data(){
        return {
          expand: false,
        }
      },
      methods: {
          log(){
            console.log(this.expand);
          }
      },
      watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      console.log(this.expand);
      this.expand = false
    }
  },
      computed: mapState(['role','myProjects']),
  }
</script>
