<template>
  <v-app>
    <v-main >
            <Toaster  position="top-right" expand :visibleToasts="9" richColors closeButton :enableHtml='true' />
      <!-- <CustomSidebar />
      <TopBar />
      <router-view/> -->
      <div class="container-fluid main_container" >
        <div class="row">
          <div class="d-none-xs d-none-sm col-md-1 col-xl-2 col sidebarCol" id="mainsidebar" v-if='isAuth'>
            <CustomSidebar />
          </div>
          <div :class="isAuth ? 'col-12 col-md-11 col-xl-10 col': 'col-12' " id="mainContent">
            <TopBar v-if='isAuth'/>
            <router-view :key="$route.fullPath" />
            <div id="footer"  v-if='isAuth'>
                  <img :src="require('@/assets/logo_innopearl.png')"  class="sidebar_logo_extra_small" alt="">

                  <small class="footerAbs">Innopearl © {{new Date().getFullYear() }}</small>
            </div>
          </div>
        </div>
      </div>


    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import CustomSidebar from '@/components/sidebar/CustomSidebar.vue';
import TopBar from '@/components/sidebar/TopBar.vue';
import { Toaster } from 'vue-sonner'
export default {
  name: 'App',
  components: {
    CustomSidebar,
    TopBar,
    Toaster
  },
  data: () => ({
    //
  }),
  mounted(){
      this.$store.commit('INITIALISESTORE')
  },
  computed: mapState(['isAuth']),
  watch: {
    '$route' (to) {
      document.title = to.name || 'Partner+ Club'
    },
  },
}
</script>

<style media="screen">
  #content{
    /* margin-left: 15%; */
    background : #EAEAEA;
    min-height: calc(100vh);
    padding-bottom: 50px;

  }

  .main_container > .row > .col{
    padding: 0px !important;
    margin: 0px !important;
  }
  .sidebarCol{
    min-height:100vh;position:relative;z-index:40;
        box-shadow: 0px 3px 6px #00000029;
  }

  .prev_step_btn,
  .next_step_btn,
  .contact_step_btn{
      display:inline-block;
  }
  .mt20{
    margin-top: 20px;
  }

  @media screen and (max-width: 1200px){
    #mainsidebar{
      display:none;
    }
    #content{
      margin-top: 100px;
    }
    #mainContent{
      width:100%;
    }


  }

  @media screen and (max-width: 990px){
    .prev_step_btn,
    .next_step_btn,
    .contact_step_btn{
      font-size: 12px;

  }

  button.step{
    display: flex;
flex-direction: column;

  }
  }

.v-theme--myCustomLightTheme{
        --v-disabled-opacity: 1 !important;
    }

    @media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 90% !important;
    }

  }

  a, a:hover{
    text-decoration: none !important;
  }

  #footer{
        background : #EAEAEA;
        padding:20px;
        text-align:center;
        font-size: 12px;
        color:#333;
  }
  #footer a{
    text-align:center;
  }

  .sidebar_logo_extra_small{
    width: 80px;
  height: 40px;
  }
  .v-btn--icon{
    border:none !important;
  }

  .footerAbs{
    position:absolute;
    bottom:20px;
    right:20px;
  }
</style>
