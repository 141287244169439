import api from "./api";

const setup = () => {

  api.interceptors.request.use(
    config => {
      const token = localStorage.getItem("innotoken");
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

};

export default setup;
